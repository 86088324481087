<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Editar rol
      </b-card-title>
    </b-card-header>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <!-- Title -->
        <validation-provider
          #default="validationContext"
          name="Nombre"
          rules="required"
        >
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="roleData.name"
              :state="getValidationState(validationContext)"
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group
          label="Tipo de permiso"
        >
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="roleData.typePermission"
              name="some-radios"
              value="all-access"
            >
              Acceso Total
            </b-form-radio>
            <b-form-radio
              v-model="roleData.typePermission"
              name="some-radios"
              value="no-access"
            >
              Acceso Parcial
            </b-form-radio>
          </div>
        </b-form-group>
        <!-- PERMISSION TABLE -->
        <b-card
          v-if="roleData.typePermission === 'no-access'"
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Permisos</span>
            </b-card-title>
          </b-card-header>

          <b-form-group>
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="roleData.permissions"
              name="flavour-1"
              class="demo-inline-spacing"
            >
              <b-row class="ml-0">
                <b-col
                  v-for="element in permissionOptions"
                  :key="element.id"
                  cols="12"
                  md="3"
                >
                  <b-form-checkbox :value="element.id">
                    {{ element.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>

            </b-form-checkbox-group>

          </b-form-group>

        </b-card>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Actualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$router.push({ name: 'roles'})"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BFormInvalidFeedback,
  BFormCheckbox, BCardTitle, BCardHeader, BFormRadio, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import settingStoreModule from '@/views/dramox/setting/settingStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import router from '@/router'

export default {
  name: 'SettingRoleCreate',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCardTitle,
    BCardHeader,
    BFormRadio,
    BFormCheckboxGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-settings'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankRoleData = {
      name: '',
      typePermission: 'all-access',
      permissions: [],
    }

    const selectedTypePermission = ref('all-access')
    const selectedPermissions = ref([])
    const permissionOptions = ref([])

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))
    const resetuserData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData))
    }

    store.dispatch('app-settings/fetchRole', { id: router.currentRoute.params.id })
      .then(response => {
        const {
          id, name, permissions, type_permission: typePermission,
        } = response.data.payload.results.role
        roleData.value.id = id
        roleData.value.name = name
        roleData.value.typePermission = typePermission
        roleData.value.permissions = permissions.map(item => item.id)
      })
      .catch(error => {
        showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
      })

    const onSubmit = () => {
      if (roleData.value.typePermission === 'no-access' && roleData.value.permissions.length === 0) {
        showNotification({ title: 'Oops!,debes seleccionar por lo menos 1 permiso', icon: 'AlertTriangleIcon', variant: 'danger' })
        return
      }
      if (roleData.value.typePermission === 'all-access') roleData.value.permissions = []


      store.dispatch('app-settings/updateRole', { id: roleData.value.id, body: roleData.value })
        .then(() => {
          showNotification({ title: 'Rol actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          router.push({ name: 'roles' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-settings/fetchPermissions')
      .then(response => {
        permissionOptions.value = response.data.payload.results
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      roleData,

      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      selectedTypePermission,
      selectedPermissions,
      permissionOptions,
    }
  },
}
</script>

<style scoped>

</style>
